import React from "react";
import { Link } from "gatsby";
import Grid from "@material-ui/core/Grid";
import { Box, Button, IconButton, Collapse } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import MuiLink from "@material-ui/core/Link";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import HorizontalLogo from "../images/v2/campaigns/Horizontal-logo.png";
import Logo from "../images/v2/PC-vertical-logo.svg";
import callImg from "../images/v2/campaigns/call.svg";
import Divider from "@material-ui/core/Divider";
import singaporeSmeBannerImg from "../images/v2/campaigns/dashboardBackgroundImg.png";
import singaporeSmeSalesCRMImg from "../images/v2/campaigns/singapore-sme-sales-crm-img-v3.png";
import useWidth from "../hooks/useWidth";
import requestDemoBGImg from "../images/v2/campaigns/request-demo-bg.svg";
import loadable from "@loadable/component";
import WhiteButton from "../components/v2/WhightButton";
import IntegratedCRMBgImg from "../images/v2/home/integrated-crm-bg.svg";
import singaporeSmeTestimonialImg from "../images/v2/campaigns/Home page creative  v3 (2).png";
import faqbackgroundImg from "../images/v2/campaigns/background.png";
import { conversionEventOnCall } from "./singapore-sme-v2";
import SEO from "../components/SEO";
import card1Img from "../images/v2/campaigns/card1media.png";
import card2Img from "../images/v2/campaigns/whatsappCard2Img.png";
import card3Img from "../images/v2/campaigns/whatsappCard3Img.png";
import whatsappBackgroundImg from "../images/v2/campaigns/whatsappBackgroundImg.png";
import whatsapptextImg1 from "../images/v2/campaigns/whatappTextImg1.png";
import whatsapptextImg2 from "../images/v2/campaigns/whatappTextImg2.png";
import whatsapptextImg3 from "../images/v2/campaigns/whatappTextImg3.png";
import whatsappTitleImage from "../images/v2/campaigns/whatsappTitleImage.png";
import reactangelImage from "../images/v2/campaigns/reactangleCornerImage.png";
import yellowLine from "../images/v2/campaigns/yellowLine.png";
import smeSupportLogo from "../images/v2/campaigns/smeSupportLogo.png";

const useStyles = makeStyles((theme) => ({
  marginAuto: {
    margin: "0 auto",
  },
  header: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.29)",
    padding: ".65rem 0 .25rem",
  },
  backgroundWhite: {
    position: `relative`,
    backgroundColor: `#fff`,
  },
  faqbackground: {
    position: `relative`,
    background: `#fff url(${faqbackgroundImg}) no-repeat center`,
    backgroundSize: "cover",
    margin: "79.5px 0 ",
  },
  whatsappBackground: {
    position: "relative",
    background: `#fff url(${whatsappBackgroundImg}) no-repeat center`,
    width: "100%",
    backgroundSize: "cover",
  },
  singaporeSmeBanner: {
    position: `relative`,
    background: `#fff url(${singaporeSmeBannerImg}) no-repeat center`,
    backgroundSize: "cover",
    width: "100%",
    [theme.breakpoints.up(1600)]: {
      position: `relative`,
      background: `#fff url(${singaporeSmeBannerImg}) no-repeat center`,
      backgroundSize: "cover",
      width: "100%",
    },
  },
  singaporeSmeBannerRightImg: {
    position: `absolute`,
    backgroundSize: "contain",
    paddingTop: "50px",
    right: `-25%`,
    backgroundPosition: `right`,
    width: `100%`,
    height: `100%`,
    top: `25%`,
    [theme.breakpoints.down(1120)]: {
      display: `none`,
    },
    [theme.breakpoints.up(1300)]: {
      right: `-20%`,
    },
    [theme.breakpoints.up(1600)]: {
      right: `-15%`,
    },
    [theme.breakpoints.up(1900)]: {
      right: `0%`,
    },
  },
  singaporeSmeFromBox: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "0",
    },
    [theme.breakpoints.up("xs")]: {
      paddingTop: "2rem",
    },
  },
  listItem: {
    paddingLeft: "0px",
  },
  listItemText: {
    "& *": {
      fontSize: 18,
      color: "#2e3f4f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 26,
    backgroundColor: "#15a323",
    borderRadius: "50%",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
  },
  singaporeSmeFrom: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 2px 5px 0 #b7b7b7",
    backgroundSize: "430px 522px",
    position: "relative",
    zIndex: "1",
    margin: "0 auto",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  smeSupportedByLogo: {
    [theme.breakpoints.down("xs")]: {},
  },
  singaporeSmeFromHeader: {
    background: "#2e3f4f",
    borderRadius: "10px 10px 0 0",
    padding: ".6rem .25rem",
    letterSpacing: ".32px",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  salesProcessCard: {
    border: "none",
    boxShadow: "none",
    marginBottom: theme.spacing(4),
  },
  cardHeaderAvatar: {
    height: 100,
    width: 100,
    background: "#FFF",
    boxShadow: "1px 1px 7px 0 rgb(0 0 0 / 19%)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: -68,
    [theme.breakpoints.down("sm")]: {
      height: 60,
      width: 60,
    },
  },
  img: {
    backgroundSize: "contain",
  },
  nopad: {
    paddingLeft: "0",
    paddingTop: "0",
  },
  nopadtpbtm: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  listWidth: {
    width: "50%",
  },
  colorChange: {
    color: "#ff7a59",
    fontSize: "30px",
    fontWeight: "700",
    letterSpacing: ".4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  whatsappContainerMargin: {
    marginLeft: "100px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0",
    },
  },
  singaporeSmeSalesCRMImg: {
    marginTop: "-70px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0",
    },
  },
  cardHeader: {
    background: "#f2f7fd",
    marginLeft: 54,
  },
  landingbtn: {
    padding: "10px 45px",
    fontSize: "22px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      padding: "10px 35px",
      fontSize: "18px",
    },
  },
  IntegratedCRMBg: {
    background: `url(${IntegratedCRMBgImg}) center bottom no-repeat`,
    backgroundSize: "cover",
  },
  whitespaceNowrap: {
    whiteSpace: "nowrap",
  },
  pepperCloudCRMHelps: {
    [theme.breakpoints.up("md")]: {
      backgroundSize: "100%",
      backgroundPosition: "center",
    },
  },
  pepperCloudCRMHelpsSpan: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#ffffff",
      padding: "0 1rem",
    },
  },
  pepperCloudCRMHelpsContent: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#fff7e0",
    padding: "20px 0 20px 0",
  },
  pepperCloudCRMHelpsContentListBox: {
    padding: "0rem 1rem",
  },
  pepperCloudCRMHelpsContentList: {
    listStyle: "none",
    padding: "0px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  pepperCloudCRMHelpsContentListItem: {
    display: "inline-block",
    paddingRight: "2rem",
    marginRight: "2rem",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none !important",
    },
  },
  copyFooter: {
    color: "#cbcccd",
    textAlign: "end",
    paddingRight: "23px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  yellowImageLine: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
  },
  pepperCloudCRMHelpsContentListItemSpan: {
    color: "#e2ab10",
    fontSize: "61px",
    fontWeight: "600",
    lineHeight: "normal",
    fontFamily: "Poppins",
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  faqCard: {
    borderRadius: "20px",
    boxShadow: "1px 1px 10px 0 rgba(0, 0, 0, 0.16)",
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  ConnectCrm: {
    background: "#ebf9fc",
    padding: "20px",
  },
  PCBenefits: {
    background: "#ebf9fc",
  },
  smeBannerTxthead: {
    padding: "3px",
    margin: "0",
    marginLeft: "5px",
  },
  martop: {
    marginTop: "10px",
  },
  whatsapptextImg3: {
    position: "absolute",
    right: "23px",
    top: "485px",
    [theme.breakpoints.down("md")]: {
      left: "131px",
      top: "488px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "447px",
      left: "61px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "25px",
      top: "425px",
    },
  },
  whatsapptextImg2: {
    position: "absolute",
    right: "37px",
    top: "490px",
    [theme.breakpoints.down("sm")]: {
      top: "490px",
      right: "204px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "455px",
      right: "24px",
    },
  },
  whatsapptextImg1: {
    position: "absolute",
    left: "131px",
    top: "488px",
    [theme.breakpoints.down("md")]: {
      left: "131px",
      top: "488px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "505px",
      left: "119px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "31px",
      top: "461px",
    },
  },
  rectangelImageContainer: {
    position: "absolute",
    right: "-15px",
    top: "99px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  whatsappDisclaimer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "24px",
    paddingRight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      paddingRight: "0",
    },
  },
  reactangelImageContainer1: {
    position: "absolute",
    right: "-18px",
    top: "99px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  yellowContiner: {
    border: "5px solid #e2ab10",
    right: "348px",
    bottom: "-13px",
    borderRadius: "13px",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  whatsappTitleImageContainer: {
    position: "absolute",
    top: "60px",
    paddingLeft: "6rem",

    [theme.breakpoints.down("md")]: {
      paddingLeft: "6rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  faqbackgroundDivder: {
    marginTop: "144px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "15px",
    },
  },
  smeSupportLogo: {
    width: "100%",
    padding: "0 17px",
    [theme.breakpoints.down("xs", "sm")]: {
      width: "100%",
      padding: "0 ",
    },
  },
  relativeContainer: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "50px",
    },
  },
  relativeContainerSide: {
    position: "relative",
    marginTop: "120px",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
  },
  relativeContainer2: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
  },
  cardindexBackground: {
    boxShadow: "none",
    borderRadius: "12px",
    border: "solid 1px #0893af",
  },
  cardImg: {
    width: "100%",
    height: "auto",
    transform: "scale(1.1)",
  },
  linkHeader: {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  supportedByLogo: {
    marginBottom: "22px",
    color: "#2e3f4f",
    fontSize: "13px",
    fontWeight: "700",
    letterSpacing: "-.5px",
    marginTop: 0,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "12px",
      fontSize: "16px",
      marginTop: "9px",
    },
  },
  listHeading: {
    paddingLeft: "228px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
    },
  },
  pcWebForm: {
    width: "100%",
    marginTop: "-7px",
    height: "600px",
    [theme.breakpoints.down("sm")]: {
      height: "600px",
    },
  },
  webformContainer: {
    height: "600px",
    [theme.breakpoints.down("sm")]: {
      height: "600px",
    },
  },
}));

const listOne = `WhatsApp sales automation
AI-powered sales CRM software`.split(`
`);

const listTwo = `Built for SMEs
Local support 24*7`.split(`
`);

const listThree = `Amplify reach with WhatsApp broadcast.
Sell more with WhatsApp catalog.
Auto-reply with message templates.`.split(`
`);

const listFour = `Assist customers with AI chatbot.
Auto-capture leads from WhatsApp messages.
Save manual hours with WhatsApp automation.`.split(`
`);

const CompanyCarousel = loadable(() =>
  import("../components/v2/CompanyCarousel")
);
const GoogleAdV3 = () => {
  const myRef = React.useRef(null);

  const executeScroll = () => {
    myRef.current.scrollIntoView();
  };

  const classes = useStyles();

  const width = useWidth();
  const [activeCalendar, setActiveCalendar] = React.useState(0);
  const salesCRMSolution = [
    {
      title: "I’m an SME. How can CRM with WhatsApp benefit us?",
      description: (
        <>
          WhatsApp CRM system can be an effective sales tool for SMEs. Many Dubai-based small-medium businesses leverage WhatsApp CRM for their sales and marketing needs. The CRM solution enables you to provide personalised customer service, streamline your sales process, and track all customer interactions in one place. With features such as workflow automation, message templates, WhatsApp flows, AI-powered automated replies, chatbots, etc. you can save time and focus on growing your business.
        </>
      ),
    },
    {
      title: "Is it possible to use my existing business number for WhatsApp CRM?",
      description:
        "Yes, you can use your existing number for WhatsApp through the WhatsApp CRM tool.",
    },
    {
      title:
        "Can all team members communicate with customers using a single WhatsApp number?",
      description:
        "Yes, WhatsApp CRM enables your entire team to chat with leads and customers from a single WhatsApp number.",
    },
    {
      title: "How long does it take to set up WhatsApp for my business?",
      description:
        "Once you have all the prerequisites in place, you can begin using WhatsApp through your CRM within a few hours.",
    },
    {
      title:
        "Does Pepper Cloud charge for WhatsApp messages sent through the CRM?",
      description: (
        <>
          No, Pepper Cloud doesn’t charge for the messages. However, you may incur charges from WhatsApp. For more information, refer to {" "}
          <a
            href="https://blog.peppercloud.com/whatsapp-api-pricing-everything-you-need-to-know"
            style={{ color: "#198cb5" }}
          >
            WhatsApp pricing details.
          </a>
        </>
      ),
    },
    {
      title:
        "What other messaging channel integrations does the Pepper Cloud CRM system offer?",
      description:<>
        Pepper Cloud CRM is a multi-channel integrated platform that connects with WhatsApp, Facebook Messenger, Instagram, Telegram, and others. Explore all {" "}
         <a
            href="https://peppercloud.com/integration"
            style={{ color: "#198cb5" }}
          >
            CRM integrations {" "}
          </a>
          here.
        </>
    },
    {
      title: "How to use WhatsApp Business on multiple devices?",
      description: (
        <>
          If you are using WhatsApp Business API, you have to first set up a WhatsApp API account and integrate it with CRM. Then, you can easily use WhatsApp Business on multiple devices via WhatsApp CRM. Read our blog for detailed instructions on  {" "}
          <a
            href="https://blog.peppercloud.com/whatsapp-cloud-api-guide/#how-to-get-whatsapp-cloud-api-for-your-business"
            style={{ color: "#198cb5" }}
          >
            setting up your WhatsApp API account {" "}
          </a>
          for your business.
        </>
      ),
    },
    {
      title: "What is WhatsApp marketing?",
      description: (
        <>
        <a
            href="https://blog.peppercloud.com/whatsapp-marketing-101-proven-strategies-for-success/"
            style={{ color: "#198cb5" }}
          >
            WhatsApp marketing {" "}
          </a>
        is a strategy that businesses like yours can use to leverage the popular messaging app, WhatsApp. With this, you can connect and engage with current and potential customers. It involves utilising WhatsApp's features to achieve various marketing goals.
        </>
      ),
    },
    {
      title: "How to do WhatsApp marketing with WhatsApp CRM?",
      description: (
        <>
          WhatsApp CRM offers multiple features that can assist in your marketing. A few of them are:{" "}<br/>
          <br/>

          <ul>
        <li><strong>Catalogs</strong>: You can showcase your products and offerings through WhatsApp catalogs and sell quickly.</li>
        <br/>
        <li><strong>Message broadcast</strong>: Broadcast promotional messages about your offerings, including new products, deals, and discounts, and more.</li>
        <br/>
        <li><strong>Chatbots</strong>: WhatsApp CRM offers AI-powered chatbots that answer FAQs and customer queries 24/7.</li>
        <br/>
        <li><strong>Automated flows</strong>: With predefined flows, capture leads, book appointments, share brochures, etc.</li>
      </ul>




          {/* Catalogs : You can showcase your products and offering through WhatsApp catalogs and sell quickly.<br/>
          <br/>
          Message broadcast: Broadcast promotional messages about your offerings, including new products, deals, and discounts, and more.<br/>
          <br/>
          Chatbots: WhatsApp CRM offers AI-powered chatbot that answers FAQs and customer queries 24/7.<br/>
          <br/>
          Automated flows: With predefined flows, capture leads, book appointments, share brochures, etc. */}

        </>
      ),
    },
  ];

  React.useEffect(() => {
    const pcIframe = document.getElementById("pc-iframe");
    pcIframe.src = pcIframe.src + window.location.search;
    if (window.location.search)
      pcIframe.src = pcIframe.src + "&page_title=" + document.title;
    else pcIframe.src = pcIframe.src + "?page_title=" + document.title;
  }, []);

  return (
    <React.Fragment>
      <SEO
        canonical="/whatsapp-crm-system-dubai-uae"
        description="Try Dubai's best WhatsApp CRM with features like multiple user support, broadcast, AI chatbot and automated Workflow for amplifying your sales business success."
        keywords="Dubai WhatsApp CRM,crm for whatsapp,crm with whatsapp,whatsapp crm tool,Dubai WhatsApp CRM system,Dubai WhatsApp,CRM,Dubai CRM Software,crm middle east dubai"
        pathname="/whatsapp-crm-system-dubai-uae"
        title="Dubai's Best CRM with WhatsApp Integration for SMEs | Pepper Cloud"
      />
      <Box className={classes.header}>
        <Box component={Container}>
          <Grid alignItems="center" container justifyContent="center">
            <Grid item md={7} sm={12}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="flex-start"
                my={1}
              >
                <Box alignItems="center" display="flex">
                  <Link to="/">
                    <Box
                      alt="Pepper Cloud CRM"
                      component={"img"}
                      height={{ sm: 36, xs: 36 }[width] || 58}
                      mr={{ sm: 1, xs: 1 }[width] || 2}
                      src={HorizontalLogo}
                    />
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item md={5} sm={12}>
              <Box
                alignItems="flex-end"
                display="flex"
                justifyContent="flex-end"
                my={1}
                textAlign="right"
              >
                <HeaderTypography
                  className="header"
                  color="#2e3f4f"
                  fontSize={{ sm: 13, xs: 13 }[width] || 18}
                  fontWeight={600}
                  m={0}
                  mr={{ sm: 2, xs: 1 }[width] || 2}
                  overrideClassName
                  component="h4"
                >
                  Book a FREE Demo
                </HeaderTypography>
                <MuiLink href="tel:+6597510475" onClick={conversionEventOnCall}>
                  <Box alignItems="center" display="flex">
                    <Box
                      alt="Call to Pepper Cloud"
                      component={"img"}
                      height={{ sm: 14, xs: 14 }[width] || 20}
                      mr={{ sm: 0, xs: 0 }[width] || 1}
                      src={callImg}
                    />
                    <ParagraphTypography
                      className={classes.whitespaceNowrap}
                      color="#0291ae"
                      fontSize={{ sm: 14, xs: 14 }[width] || 20}
                      fontWeight={700}
                      m={0}
                      overrideClassName
                    >
                      +971 52 607 3989
                    </ParagraphTypography>
                  </Box>
                </MuiLink>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Banner */}
      <Box className={classes.singaporeSmeBanner}>
        <Container>
          <Grid alignItems="flex-start" container justify="center" mt={3}>
            <Grid item md={7} sm={12}>
              <Box>
                <HeaderTypography
                  fontSize={{ sm: 36, xs: 34 }[width] || 42}
                  fontWeight={600}
                  lineHeight={1.25}
                  mb={4}
                  mt={3}
                  overrideClassName
                  component="h1"
                >
                  Drive Sales with Dubai’s best{" "}
                  <span style={{ color: "#15a323" }}>WhatsApp CRM</span>
                </HeaderTypography>
                <ParagraphTypography
                  className={classes.smeBannerTxt}
                  color="#2e3f4f"
                  component="h2"
                  font-family="SourceSansPro"
                  fontSize={18}
                  mb={3}
                  mt={0}
                >
                  With WhatsApp CRM, connect with your customers in real-time, respond to messages instantly, and convert every sales conversation into winning opportunities.
                </ParagraphTypography>
                <Box pr={{ sm: "0px", xs: "0px" }[width] || "3rem"}>
                  <Grid
                    alignItems="center"
                    container
                    justifyContent="start"
                    sm={12}
                    xs={12}
                  >
                    <Grid item md={6} sm={12}>
                      <Box>
                        <List className={classes.nopadtpbtm} pl={0}>
                          {listOne.map((each) => (
                            <ListItem
                              alignItems="center"
                              className={classes.nopad}
                              key={each}
                            >
                              <ListItemIcon>
                                <CheckIcon className={classes.listItemIcon} />
                              </ListItemIcon>
                              <ListItemText className={classes.listItemText}>
                                {each}
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Box>
                        <List className={classes.nopadtpbtm} pl={0}>
                          {listTwo.map((each) => (
                            <ListItem
                              alignItems="center"
                              className={classes.nopad}
                              key={each}
                            >
                              <ListItemIcon>
                                <CheckIcon className={classes.listItemIcon} />
                              </ListItemIcon>
                              <ListItemText className={classes.listItemText}>
                                {each}
                              </ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <br />
                <Box display={{ sm: "block", xs: "block" }[width] || "flex"}>
                  <Box
                    alt="Dubai’s Best WhatsApp CRM System"
                    component={"img"}
                    maxHeight="450px"
                    maxWidth="100%"
                    src={singaporeSmeTestimonialImg}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={5} sm={9} xs={12}>
              <Box
                className={classes.singaporeSmeFromBox}
                px={1}
                textAlign="center"
              >
                {/* <Box
                  alt="SG Digital"
                  component={"img"}
                  src={smeSupportLogo}
                  className={classes.smeSupportLogo}
                /> */}

                <Box className={classes.singaporeSmeFrom} ref={myRef}>
                  <HeaderTypography
                    className={classes.singaporeSmeFromHeader}
                    color="#fff"
                    component="h3"
                    fontWeight={600}
                    m={0}
                    textAlign="center"
                  >
                    Contact Us
                    </HeaderTypography>
                  <Box className={classes.webformContainer}>
                    <Box
                      className={classes.pcWebForm}
                      border="none"
                      component="iframe"
                      id="pc-iframe"
                      overflow="hidden"
                      referrerpolicy="unsafe-url"
                      src="https://app.peppercloud.com/form/12b38be0-edf8-4862-ab2b-8d9dd977e7c7/embed"
                      width="99%"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Content*/}
      <Box
        pb={2}
        pt={{ sm: 12, xs: 12 }[width] || 12}
        textAlign="center"
        className={classes.backgroundWhite}
      >
        <Container>
          <Box className={classes.listHeading}>
            <HeaderTypography
              component="h2"
              fontSize={35}
              fontWeight={600}
              maxWidth={725}
              alignItems="center"
              mb={3}
              mt={0}
              textAlign="center"
            >
              Seamlessly manage and streamline your sales with{" "}
              <span style={{ color: "#15a323" }}>WhatsApp CRM</span>
            </HeaderTypography>
          </Box>
          <Box>
            <Grid
              alignItems="center"
              container
              justifyContent="center"
              sm={12}
              md={12}
              width="100%"
              className={classes.whatsappContainerMargin}
            >
              <Grid item xs={12} sm={6}>
                <Box>
                  <List className={classes.nopadtpbtm} pl={0}>
                    {listThree.map((each) => (
                      <ListItem
                        alignItems="center"
                        className={classes.nopad}
                        key={each}
                      >
                        <ListItemIcon>
                          <CheckIcon className={classes.listItemIcon} />
                        </ListItemIcon>
                        <ListItemText className={classes.listItemText}>
                          {each}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <List className={classes.nopadtpbtm} pl={0}>
                    {listFour.map((each) => (
                      <ListItem
                        alignItems="center"
                        className={classes.nopad}
                        key={each}
                      >
                        <ListItemIcon>
                          <CheckIcon className={classes.listItemIcon} />
                        </ListItemIcon>
                        <ListItemText className={classes.listItemText}>
                          {each}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            alt="CRM with WhatsApp Integration - Dashboard"
            component={"img"}
            maxWidth="80%"
            maxHeight="80%"
            src={singaporeSmeSalesCRMImg}
            className={classes.singaporeSmeSalesCRMImg}
          />
        </Container>
      </Box>

      {/* Content */}
      <Box textAlign="center" className={classes.pepperCloudCRMHelpsContent}>
        <HeaderTypography component="h2" fontSize={35} fontWeight={600}>
          Supercharge your business with {" "} 
          <span style={{ color: "#15a323" }}>WhatsApp CRM</span>
          {" "}tool
        </HeaderTypography>
        <Box
          py={{ sm: 4, xs: 3 }}
          className={classes.pepperCloudCRMHelpsContent}
        >
          <Container>
            <Box className={classes.pepperCloudCRMHelpsContentListBox}>
              <ul className={classes.pepperCloudCRMHelpsContentList}>
                <li className={classes.pepperCloudCRMHelpsContentListItem}>
                  <Box alignItems="center" style={{ display: "flex" }}>
                    <div>
                      <div
                        className={
                          classes.pepperCloudCRMHelpsContentListItemSpan
                        }
                      >
                        90%+
                      </div>
                      <div>Open rate</div>
                    </div>
                    <div className={classes.yellowImageLine}>
                      <img src={yellowLine} style={{ marginLeft: "47px" }} />
                    </div>
                  </Box>
                </li>
                <li className={classes.pepperCloudCRMHelpsContentListItem}>
                  <Box alignItems="center" style={{ display: "flex" }}>
                    <div>
                      <div
                        className={
                          classes.pepperCloudCRMHelpsContentListItemSpan
                        }
                      >
                        70%+
                      </div>
                      <div>Deal closing rate</div>
                    </div>
                    <div className={classes.yellowImageLine}>
                      <img src={yellowLine} style={{ marginLeft: "47px" }} />
                    </div>
                  </Box>
                </li>
                <li className={classes.pepperCloudCRMHelpsContentListItem}>
                  <Box alignItems="center" style={{ display: "flex" }}>
                    <div>
                      <div
                        className={
                          classes.pepperCloudCRMHelpsContentListItemSpan
                        }
                      >
                        60%
                      </div>
                      <div style={{ width: "109%" }}>
                        More revenue generation
                      </div>
                    </div>
                    <div className={classes.yellowImageLine}>
                      <img src={yellowLine} style={{ marginLeft: "47px" }} />
                    </div>
                  </Box>
                </li>
                <li className={classes.pepperCloudCRMHelpsContentListItem}>
                  <Box alignItems="center" style={{ display: "flex" }}>
                    <div>
                      <div
                        className={
                          classes.pepperCloudCRMHelpsContentListItemSpan
                        }
                      >
                        20%
                      </div>
                      <div>More conversation rate</div>
                    </div>
                  </Box>
                </li>
              </ul>
            </Box>
            <Box textAlign="center">
              <Button
                className="book-demo-btn"
                color="secondary"
                size="large"
                variant="contained"
                onClick={executeScroll}
                component="h4"
              >
                Book a Free Demo
              </Button>
            </Box>
            <Box className={classes.whatsappDisclaimer}>
              *WhatsApp statistics from multiple sources
            </Box>
          </Container>
        </Box>
      </Box>

      {/* whatsapp card section */}
      <Box className={classes.whatsappBackground}>
        <div style={{ position: "relative" }}>
          <HeaderTypography
            component="h2"
            fontSize={35}
            fontWeight={600}
            mb={3}
            mt={0}
            pt={6}
            pb={5}
            textAlign="center"
          >
            <span style={{ color: "#15a323" }}>
              WhatsApp CRM{" "}
              <img
                src={whatsappTitleImage}
                alt="WhatsApp Integration"
                className={classes.whatsappTitleImageContainer}
              />
            </span>
            <br />
            <span>Built to support all industries</span>
          </HeaderTypography>
        </div>

        <Container>
          <Grid alignItems="center" container spacing={4}>
            <Grid
              item
              md={4}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className={classes.relativeContainer}>
                <Box className={classes.reactangelImageContainer1}>
                  <img src={reactangelImage} height={55} width={55} />
                </Box>

                <Box className={classes.relativeContainerSide}>
                  <Card
                    className={`${classes.cardindexBackground}`}
                    style={{ width: "100%" }}
                  >
                    <CardContent>
                      <HeaderTypography
                        component="h3"
                        fontSize={22}
                        fontWeight={600}
                        mt={0}
                      >
                        Beauty salons
                      </HeaderTypography>
                      <Box mt={0} mb={0}>
                        <Divider dark />
                      </Box>
                      <ParagraphTypography
                        component="h3"
                        fontSize={18}
                        mt={2}
                        textAlign={
                          { sm: "center", xs: "center" }[width] || "left"
                        }
                      >
                        Beauty salons and Wellness centres use WhatsApp CRM to
                        offer convenient appointment booking through WhatsApp,
                        send auto-appointment reminders to reduce no-shows,
                        broadcast promotional messages, and get instant customer
                        feedback.
                      </ParagraphTypography>
                    </CardContent>
                    <CardMedia
                      alt="CRM for SMEs"
                      component="img"
                      image={card1Img}
                      className={classes.cardImg}
                    />
                  </Card>
                  <Box
                    src={whatsapptextImg1}
                    component={"img"}
                    width="255px"
                    className={classes.whatsapptextImg1}
                    alt="Send auto reminder"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className={classes.relativeContainer}>
                <Box
                  className={classes.yellowContiner}
                  height={55}
                  width={55}
                />
                <Box className={classes.relativeContainer}>
                  <Box className={classes.relativeContainer2}>
                    <Card
                      className={`${classes.cardindexBackground}`}
                      style={{ width: "100%" }}
                    >
                      <CardContent>
                        <HeaderTypography
                          component="h3"
                          fontSize={22}
                          fontWeight={600}
                          mt={0}
                        >
                          Food delivery
                        </HeaderTypography>
                        <Box mt={0} mb={0}>
                          <Divider dark />
                        </Box>
                        <ParagraphTypography
                          component="h3"
                          fontSize={18}
                          mt={2}
                          textAlign={
                            { sm: "center", xs: "center" }[width] || "left"
                          }
                        >
                          Food delivery businesses use WhatsApp CRM to auto-send
                          order confirmations and food delivery tracking links,
                          respond to customer queries and resolve issues in real
                          time, and learn about their customers' preferences
                          with interactive polls.
                        </ParagraphTypography>
                      </CardContent>
                      <CardMedia
                        alt="CRM for food industry"
                        component="img"
                        className={classes.cardImg}
                        image={card2Img}
                      />
                    </Card>
                    <Box
                      src={whatsapptextImg2}
                      component={"img"}
                      width="255px"
                      className={classes.whatsapptextImg2}
                      alt="Chat with customer with WhatsApp CRM"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              md={4}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className={classes.relativeContainer}>
                <Box className={classes.rectangelImageContainer}>
                  <img src={reactangelImage} height={55} width={55} />
                </Box>
                <Box className={classes.relativeContainerSide}>
                  <Card
                    className={`${classes.cardindexBackground}`}
                    style={{
                      width: "100%",
                    }}
                  >
                    <CardContent>
                      <HeaderTypography
                        component="h3"
                        fontSize={22}
                        fontWeight={600}
                        mt={0}
                      >
                        Travel agency
                      </HeaderTypography>
                      <Box mt={0} mb={0}>
                        <Divider dark />
                      </Box>
                      <ParagraphTypography
                        component="h3"
                        fontSize={18}
                        mt={2}
                        textAlign={
                          { sm: "center", xs: "center" }[width] || "left"
                        }
                      >
                        Travel agencies use WhatsApp CRM to send personalised
                        tour packages, collect customer documents to book
                        tickets, share travel itineraries, and blast out
                        exciting promotional tour packages.
                      </ParagraphTypography>
                    </CardContent>
                    <CardMedia
                      alt="CRM Software for Travel Industry"
                      component="img"
                      className={classes.cardImg}
                      image={card3Img}
                    />
                  </Card>
                  <Box
                    src={whatsapptextImg3}
                    component={"img"}
                    width="255px"
                    className={classes.whatsapptextImg3}
                    alt="Booking management with WhatsApp CRM"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* clients Sections */}

      <Box>
        <Box mb={0} className={classes.faqbackgroundDivder}>
          <Divider light />
        </Box>
        <Container>
          <HeaderTypography
            className={classes.pepperCloudCRMHelps}
            component="h2"
            fontSize={35}
            fontWeight={600}
            my={4}
            textAlign="center"
          >
            <span fontWeight={600}>
              Best CRM tool with WhatsApp integration <br /> trusted by many
              clients
            </span>
          </HeaderTypography>
          <br />
          <br />
          <CompanyCarousel className={classes.cimg} />
        </Container>
      </Box>

      <Box mb={8} className={classes.faqbackground}>
        <Box mt={1} mb={0}>
          <Divider light />
        </Box>
        <Container>
          <section>
            <HeaderTypography
              className={classes.pepperCloudCRMHelps}
              fontSize={35}
              fontWeight={600}
              my={4}
              textAlign="center"
            >
              Frequently asked questions
            </HeaderTypography>
            <Card className={classes.faqCard}>
              <Box padding={3}>
                {salesCRMSolution.map((each, index) => (
                  <div key={each.title}>
                    <Box my={2}>
                      <Box
                        alignItems="center"
                        component="h3"
                        display="flex"
                        justifyContent="flex-start"
                        onClick={() =>
                          setActiveCalendar(
                            index === activeCalendar ? -1 : index
                          )
                        }
                      >
                        <IconButton
                          aria-label="Expand or Collapse"
                          className={classes.expendButton}
                          color="default"
                        >
                          {index !== activeCalendar ? (
                            <AddIcon fontSize="small" />
                          ) : (
                            <RemoveIcon fontSize="small" />
                          )}
                        </IconButton>
                        <Box
                          component="h3"
                          mt={0}
                          fontSize={18}
                          fontWeight="600"
                          m={0}
                          ml={3}
                        >
                          {each.title}
                        </Box>
                      </Box>
                      <Collapse in={activeCalendar === index}>
                        <ParagraphTypography
                          color="#2e3f4f"
                          fontSize={18}
                          mr={6}
                          mt={2}
                          ml={8.5}
                        >
                          {each.description}
                        </ParagraphTypography>
                      </Collapse>
                    </Box>
                    <Divider color="#707070" />
                  </div>
                ))}
              </Box>
            </Card>
          </section>
        </Container>
      </Box>

      {/* Book a free demo */}

      <Box className={classes.requestDemoRoot} py={10} textAlign="center">
        <Container>
          <Box alignItems="center" display="flex" justifyContent="center">
            <HeaderTypography
              color="common.white"
              fontSize={35}
              fontWeight={600}
              my={0}
              textAlign="center"
              component="h2"
            >
              Boost your sales with WhatsApp CRM
            </HeaderTypography>
          </Box>
          <Box mt={6} textAlign="center">
            <WhiteButton
              color="primary"
              mb={6}
              onClick={executeScroll}
              size="large"
              variant="contained"
            >
              Book a Free Demo
            </WhiteButton>
          </Box>
        </Container>
      </Box>

      {/* footer */}
      <Box py={5}>
        <Container>
          <Box textAlign="center">
            <Link to="/">
              <Box
                alt="Pepper Cloud - Best WhatsApp CRM Software"
                component={"img"}
                height="100px"
                src={Logo}
              />
            </Link>
            <ParagraphTypography
              color="#2e3f4f"
              fontFamily="Poppins"
              fontSize={{ sm: 14, xs: 14 }[width] || 20}
              m4={0}
            >
              #26, 4th floor, Unigrove Business Centre Al Garhoud, Dubai, UAE - 500001

            </ParagraphTypography>
          </Box>
        </Container>
        <Box
          color="#cbcccd"
          textAlign="end"
          paddingRight="23px"
          className={classes.copyFooter}
        >
          © Pepper Cloud - Best WhatsApp CRM Software, Dubai, UAE {new Date().getFullYear()}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default GoogleAdV3;
